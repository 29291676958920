/** @jsx jsx */
import { jsx, Link as A} from "theme-ui"
import * as React from 'react'
import { Link } from 'gatsby'
// import logo from '../images/thornley-logo.jpg'
import { StaticImage } from "gatsby-plugin-image"

const NavItem = props => (
    <li>
        <Link to={props.to}>
            {props.children}
        </Link>
    </li>
)

const Layout = ({ pageTitle, children }) => {
  return (
    <div sx={{maxWidth: 1080, mx: 'auto',}}>
      <title>{pageTitle}</title>
      <header sx={{mt: 48, mb: 24}}>
        <Link to="/">
        <StaticImage
          src="../images/logo-2021.png"
          alt=""
          placeholder="blurred"
          layout="fixed"
          width={580}
          sx={{mx: 'auto'}}
      />
        {/* <img src={logo} alt="Thornley Associates Logo" /> */}
        </Link>
    </header>
    
      <main>
        {/* <h1>{pageTitle}</h1> */}
        {children}
      </main>
  
      <footer>
         <nav sx={{mt: 16, }}>
        <ul sx={{width: 280, listStyle: 'none', lineHeight: 1.5, p: 16}}>
          <NavItem to="/">Home</NavItem>
          <NavItem to="/about">About</NavItem>
          <NavItem to="/services">Services</NavItem>
          <NavItem to="/construction">Construction</NavItem>
          <NavItem to="/owners-representation">Owners Representation</NavItem>
          <NavItem to="/work">Past Project</NavItem>
          <NavItem to="/principles">Principles</NavItem>
          <NavItem to="/contact">Contact</NavItem>

        </ul>
      </nav> 
        <p> ©{new Date().getFullYear()} Thornley Associates, Inc</p>
      </footer>
    </div>
  )
}
export default Layout